import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MICROSERVICE_WISHLIST } from '../../../../userglobals'
import styles from '../Products.module.scss'
import axios from 'axios'
import {
   addToWishlist,
   removeFromWishlist
} from '../../../store/actions'
import {
   ButtonAddToCartSimple,
   ButtonAddToWishlist,
   ButtonShare,
   IncludedItens,
   CustomOptions,
   ProductDetails,
   ProductImagesCarousel,
   ProductName,
   ProductPrice,
   ProductReviews,
   ProductSpecifications,
   Sizes,
   Tips
} from '../components'
import {
   ProductType
} from 'types'

type SimpleProductType = {
   product: ProductType;
}


const SimpleProduct: React.FC<SimpleProductType> = ({ product }) => {
   const [checked, setChecked] = useState(false);
   const [customOptions, setCustomOptions] = useState([]);
   const wishlist = useSelector((state: any) => state.wishlist);
   const userAuth = useSelector((state: any) => state.userAuth);

   const handleCustomOptionChange = (label: string, value: string, id: number) => {
      const newCustomOptions = [...customOptions];
      const indexCustom = newCustomOptions.findIndex(x => x.label === label);
      if (indexCustom === -1) {
         newCustomOptions.push({ label: label, value: value, id: id });
      } else {
         newCustomOptions[indexCustom].value = value;
      }
      setCustomOptions(newCustomOptions)
   }

   useEffect(() => {
      const alreadyChecked = wishlist?.some((x: any) => x?.product_id === product.id.toString());
      const alreadyChecked2 = wishlist?.some((x: any) => x=== product.id);
      if (alreadyChecked || alreadyChecked2) {
         setChecked(true)
      }
   }, [])

   const toggleCheckbox = () => {
      if (!checked) {
         setChecked(true)
         addToWishlist(product.id)
         if (userAuth.auth === true) {
            axios.post(MICROSERVICE_WISHLIST + "add-wishlist", { ...userAuth, productId: product.id })
            // .then(res=>console.log(res.data))
         }
      } else {
         setChecked(false)
         removeFromWishlist(product.id)
         if (userAuth.auth === true) {
            axios.post(MICROSERVICE_WISHLIST + "delete-wishlist", { ...userAuth, productId: product.id })
            // .then(res=>console.log(res.data))
         }
      }
   };

   const productImagesUrls = product.media_gallery_entries.map((item: any) => item.file);
   //console.log(product)
   return (
      <div className={styles.container}>
         <ProductImagesCarousel productImagesUrls={productImagesUrls} />
         <ProductName productName={product.name} sku={product.sku} product={product} checked={checked} toggleCheckbox={toggleCheckbox} />
         <ProductPrice child={product} />
         {product.product_template !== '1' ?
            (product.product_template === '2' || product.product_template === '3' || product.product_template === '7' || product.product_template === '8' || product.product_template === '13' ?
               <>
                  <Sizes />
                  <CustomOptions customOptions={product.options} template={product.product_template} handleCustomOptionChange={handleCustomOptionChange} customOptionsState={customOptions} />
               </> :
               <CustomOptions customOptions={product.options} template={product.product_template} handleCustomOptionChange={handleCustomOptionChange} customOptionsState={customOptions} />) : null
         }
         <ButtonAddToCartSimple simpleProduct={product} customOptions={customOptions} />
         <div className={styles.gridWishlistShareSizes}>
            <ButtonAddToWishlist product={product} checked={checked} toggleCheckbox={toggleCheckbox} />
            <ButtonShare />
         </div>
         <IncludedItens customAttributes={product.custom_attributes} />
         <ProductDetails customAttributes={product.custom_attributes} />
         <ProductSpecifications customAttributes={product.custom_attributes} disponibilidade={product.custom_attributes.findIndex(x => x.defaultFrontendLabel === 'Disponibilidade')} />
         <Tips customAttributes={product.custom_attributes} />
         <ProductReviews />
      </div>
   )
}

export default SimpleProduct
